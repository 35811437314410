export default {
    methods: {
        thumbs(item){
            if(this.thumbsList.includes(item.id)){
                this.thumbsList.splice(this.thumbsList.indexOf(item.id),1);
                // item.thumbsAmount += -1;
                this.updateThumbs(item,-1);

            }else{
                this.thumbsList.push(item.id);
                // item.thumbsAmount += 1;
                this.updateThumbs(item,1);
            }
            localStorage.setItem("thumbsNews", JSON.stringify(this.thumbsList));
        },
        updateThumbs(item,changeAmount) {
            this.$axios
              .post(this.$url + '/news_update', {
                askData:'thumbs',
                newsId:item.id,
                changeAmount:changeAmount,
              })
              .then(
                response => {
                  item.thumbsAmount = response.data.res_record;
                }
              );
          },
    },
  }