<template>
<div class="section-wrapper">
  <div class="sub-section-wrapper">
      <breadcrumbs :content="breadcrumbsContents"></breadcrumbs>
    <div class="wrapper-box">
      <div class="list-wrapper">
        <div class="item-ele" v-for="(item,index) in contentItems" :key="index">
            <div class="left">
                <div class="item-title" >
                    <p @click="linkTo('/news/'+item.id)">{{item.title}}</p>
            </div>
            <div class="item-description">
                {{item.description}}
            </div>
            <div class="action" >
                <li>
                    <span>{{item.pubdate}}</span>
                </li>
                <li>
                    <Icon type="ios-book-outline" />
                    <span class="amount">{{item.readAmount}}</span>
                </li>
                <li @click="thumbs(item)" class="thumbs">
                    <Icon type="ios-thumbs-up" v-if="thumbsList.includes(item.id)"/>
                    <Icon type="ios-thumbs-up-outline" v-else/>
                    <span class="amount">{{item.thumbsAmount}}</span> 
                </li>
            </div>
            </div>
            <div class="right">
                <div class="img-wrapper">
                    <img :src="urlTrans(item.url[0])" >
                </div>
            </div>
        </div>
    </div>
    </div>
    <div class="page-wrapper">
        <Page :total="totalItems" :page-size="Tpage.currentSize" @on-change="handlePage" class-name="page-d" />
    </div>
    <Spin v-if="spin" fix size="large"></Spin>
  </div>
</div>
 
</template>
<script>
import breadcrumbs from "@/components/Libs/Breadcrumbs.vue"
import thumbs from "@/libs/mixins/Thumbs.js"
import dayjs from "dayjs"

export default {
  name: "newsList",
  components: {
    breadcrumbs
  },
  props: {
    
  },
  mixins:[thumbs],
  data() {
    return {
        thumbsList:[],
        contentItems: [
                    // {
                    //     id:1,
                    //     title: '市场监督管理总局认可和检验检测监督管理司领导视察SRCC',
                    //     description: '2020年11月6 日上午，市场监督管理总局认可和检验检测监督管理司董乐群司长、李华宁副司长莅临SRCC嘉定试验基地，指导工作。在SRCC董事长兼总经理韩斌的陪同下，两位领导在SRCC嘉定试验基地，先后走访了“城轨列车互操作综合检测与认证一体化平台”、“多点激励振动台”、“机构件疲劳试验台”等试验区域。对SRCC在检验、检测、评估和认证方面的技术能力和所做的工作进行认真细致的询问，并提出了宝贵意见。',
                    //     readAmount:265,
                    //     thumbsAmount:123,
                    //     pubdate:"2022-01-05"
                    // },
                    // {
                    //     id:2,
                    //     title: '市场监督管理总局认可和检验检测监督管理司领导视察SRCC',
                    //     description: '2020年11月6 日上午，市场监督管理总局认可和检验检测监督管理司董乐群司长、李华宁副司长莅临SRCC嘉定试验基地，指导工作。在SRCC董事长兼总经理韩斌的陪同下，两位领导在SRCC嘉定试验基地，先后走访了“城轨列车互操作综合检测与认证一体化平台”、“多点激励振动台”、“机构件疲劳试验台”等试验区域。对SRCC在检验、检测、评估和认证方面的技术能力和所做的工作进行认真细致的询问，并提出了宝贵意见。',
                    //     readAmount:265,
                    //     thumbsAmount:123,
                    //     pubdate:"2022-01-05"
                    // },
                    // {
                    //     id:3,
                    //     title: '市场监督管理总局认可和检验检测监督管理司领导视察SRCC',
                    //     description: '2020年11月6 日上午，市场监督管理总局认可和检验检测监督管理司董乐群司长、李华宁副司长莅临SRCC嘉定试验基地，指导工作。在SRCC董事长兼总经理韩斌的陪同下，两位领导在SRCC嘉定试验基地，先后走访了“城轨列车互操作综合检测与认证一体化平台”、“多点激励振动台”、“机构件疲劳试验台”等试验区域。对SRCC在检验、检测、评估和认证方面的技术能力和所做的工作进行认真细致的询问，并提出了宝贵意见。',
                    //     readAmount:265,
                    //     thumbsAmount:123,
                    //     pubdate:"2022-01-05"
                    // },
                ],
      spin: false,
      code:"news",
      lang:"中文",
      keyword:"",
      Tpage: {
            currentPage: 1,
            currentSize: 12
          },
      totalItems:0,
    };
  },
  created(){
    this.getData();
  },
  methods: {
    linkTo: function (value) {
        this.spin = true;
      location.href = value;
    },
    handlePage(value) {
      this.Tpage.currentPage = value;
      this.getData();
    },
    getData() {
      this.spin = true;
      this.$axios
        .post(this.$url + '/flist', {
          page: this.Tpage,
          askData:this.code,
          keyword:this.keyword,
          lang:this.lang,
        })
        .then(
          response => {
            this.contentItems = response.data.res_record;
            this.contentItems.sort((a,b)=>{return dayjs(b.pubdate)-dayjs(a.pubdate)});
            this.totalItems = response.data.total_n;
            this.spin = false;
          }
        );
    },
    urlTrans(url) {
      // return url;
      if(url){
        return this.$fileUrlFacWithoutRight(url);
      }else{
        return "";
      }
      
    },
    
  },
  computed:{
      breadcrumbsContents:function(){
          return [{text:'首页',url:'/'},{text:'公司新闻',url:'/news_list'},];
      },
  },
  mounted() {
      this.thumbsList = JSON.parse(localStorage.getItem("thumbsNews"));
      if(this.thumbsList===null){
          this.thumbsList = [];
      }
  },
};
</script>
<style scoped>

.section-wrapper{
  width:100%;
}

.sub-section-wrapper{
  width: 1280px;
  margin:0 auto;
  overflow: hidden;
  position: relative;
}
.wrapper-box{
 
}

.item-ele{
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.left{
    width:65%;
}

.right{
    width:30%;
}

.img-wrapper{
    width: 100%;
}

.right img{
    width:100%;
}

.item-title{
    font-size: 20px;
    font-weight: 700;
    margin:20px 0 50px 0;
}
.item-title p{
 cursor: pointer;
}

.item-description{
    color:#999;
    font-size: 14px;
    text-indent: 28px;
    line-height: 28px;
    padding: 0px 0 20px;
    word-break: break-all; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    -webkit-line-clamp: 3; 
    display: -webkit-box; 
    -webkit-box-orient: vertical;
    white-space: normal;
    height:90px;
}

.action{
    text-align: right;
}

.action li{
    list-style: none;
    display: inline;
    padding: 1px 10px;
    border-right: 1px solid #ddd;
}

.action li.thumbs{
    cursor: pointer;
}

.action li:hover{
    color: #b70005;
}

li:last-child{
    border-right:none;
}

.page-wrapper{
    text-align: center;
    padding: 50px 0;
}
.amount{
    position: relative;
    top: 1px;
    padding: 0 3px;
}

</style>